import React from 'react'
import Layout from '../components/layout'

import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="En digital design- och utvecklingsbyrå" keywords={[`gatsby`, `application`, `react`]} />
    <h1>
      Go Interactive
      <span className="blink blink--hotpink" aria-hidden="true"></span>
    </h1>
    <p className='text-light-gray'>En digital design- och utvecklingsbyrå</p>
  </Layout >
)

export default IndexPage
